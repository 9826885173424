<template>
  <main class="content">
    <div class="inner content-grid">
      <div class="left">
        <page-heading heading="Order Consumables for Delivery" icon="consumables-o.png"
          :para="success_message ? success_message : 'Please fill out the fields below to place your order.'"></page-heading>
        <error-panel v-if="!success_message"
          content="Total cash inside your bag should never exceed the contracted indemnity limit. If you are unsure, please contact your Head Office."></error-panel>
        <div>
          <div class="create-order" v-if="!success_message">
            <div id="section1" class="order-section active">
              <div class="order-section-header">Select Bags</div>
              <info-panel v-if="!success_message" style="margin: 2rem 1.5rem;"
                content="All packs arrive in sleeves of 50."></info-panel>
              <div class="order-section-body">
                <div class="order-section-content">
                  <p>Please select the bags you would like to include in your order.</p>
                  <div class="consumables-list">
                    <div class="consumables-list-item" :key="item.id" v-for="item in consumables">
                      <div class="consumables-list-checkbox">
                        <div>
                          <input v-model="selected" :value="item.id" type="checkbox" :id="'checkbox-' + item.id" />
                        </div>
                      </div>
                      <div class="consumables-list-image">
                        <a href @click.prevent="zoomImage(item.image, item.name)">
                          <img @click="zoomImage(item.image, item.name)" :src="item.image" :alt="item.name" /></a>
                      </div>
                      <div class="consumables-list-label">
                        <div>
                          <label :for="'checkbox-' + item.id">{{ item.name }}</label>
                          <a class="zoom">Click image to view
                            larger</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <error-panel v-if="error_message" :content="error_message"></error-panel>
          <success-panel v-if="success_message" :content="success_message"></success-panel>

          <div class="actions" v-if="selected.length">
            <button class="negative" @click="clearFields">
              Clear All Fields <img src="/images/icons/button-icon-cancel.png" alt="Clear all fields" />
            </button>
            <button :disabled="submit_loading" @click="submitOrder">
              <template v-if="submit_loading">
                <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
              </template>
              <template v-if="!submit_loading">
                Submit <img src="/images/icons/button-icon-submit.png" alt="submit" />
              </template>
            </button>
          </div>
        </div>
      </div>
      <right-column></right-column>
    </div>

    <modal :scrollable="true" height="auto" name="zoom" width="90%">
      <a @click.prevent="$modal.hide('zoom')" class="modal-close" href aria-label="Close">
        <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
      </a>
      <div class="zoomed">
        <img :src="zoomsrc" :alt="zoomalt" />
        <span>{{ zoomalt }}</span>
      </div>
    </modal>
  </main>
</template>

<script>
import api from "@/services/api"

export default {
  data() {
    return {
      zoomsrc: "",
      zoomalt: "",
      consumables: [],
      selected: [],
      submit_loading: false,
      error_message: "",
      success_message: ""
    }
  },
  created() {
    this.getItems()
  },
  methods: {
    zoomImage(src, alt) {
      this.zoomsrc = src
      this.zoomalt = alt
      this.$modal.show("zoom")
    },
    getItems() {
      this.loading = true
      api
        .request("get", "consumable")
        .then(res => {
          this.consumables = res.consumables
        })
        .catch(() => {
          this.error_message = "There has been an error"
        })
        .finally(() => {
          this.loading = false
        })
    },
    clearFields() {
      this.selected = []
    },
    submitOrder() {
      this.error_message = ""
      this.success_message = ""

      if (this.selected.length > 3)
        return (this.error_message = "You can only order up to a maximum of 3 items in a single order")

      this.submit_loading = true
      api
        .request("post", "consumable", { ids: this.selected })
        .then(() => {
          this.selected = []
          this.success_message =
            "You will receive your bags on one of your upcoming service days. We will also send you a confirmation email with the details of the order."
        })
        .catch((err) => {
          if (err.response.data == 'alreadyExists') {
            this.error_message = "Each consumable can only be ordered once within 7 days"
          } else {
            this.error_message = "There has been an error, please try again"
          }
        })
        .finally(() => {
          this.submit_loading = false
        })
    }
  }
}
</script>

<style scoped>
input:focus-visible {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

.order-section {
  transition: all 0.2s;
  margin-top: 2rem;
}

.active .order-section-body {
  max-height: none;
}

.order-section-header {
  cursor: pointer;
  background: #f3f3f3;
  padding: 1.5rem 2rem;
  font-size: 2.5rem;
  transition: all 0.2s;
}

.subheading {
  color: #ca0028;
  padding: 1.5rem 0;
  font-size: 2.5rem;
}

.active .order-section-header {
  background: #fff;
  color: #ca0028;
}

.active {
  box-shadow: 0px 0px 13px -2px #d3d3d3;
}

.order-section-body {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s;
  border-bottom: 0rem solid transparent;
  width: 100%;
  opacity: 0;
}

.active .order-section-body {
  border-bottom: 0.5rem solid #ca0028;
  padding: 3rem 2rem;
  padding-top: 1rem;
  display: table;
  opacity: 1;
}

.expand {
  float: right;
  height: 2rem;
  position: relative;
  top: 0.5rem;
  transition: all 0.4s;
}

.active .expand {
  transform: rotate(270deg);
}

.order-section-body p {
  margin-bottom: 2rem;
}

.order-section-body p.small {
  font-size: 1.5rem;
}

.order-section-body p:last-child {
  margin-bottom: 0;
}

.consumables-list-item {
  display: grid;
  grid-template-columns: 0.5fr 2fr 6fr;
  gap: 2rem;
}

.consumables-list-checkbox>div {
  text-align: center;
  width: 100%;
}

.consumables-list-checkbox input {
  transform: scale(1.5);
  cursor: pointer;
}

.consumables-list-label {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
}

.consumables-list-label a {
  display: block;
  font-weight: normal;
  font-size: 1.5rem;
  cursor: default;
}

.consumables-list-item>div {
  display: flex;
  align-items: center;
}

.consumables-list-image img {
  width: 15rem;
  padding: 1.5rem 0;
  margin: auto;
  cursor: pointer;
}

.zoom {
  margin-top: 0.5rem;
}

.zoom svg {
  margin-left: 0.5rem;
}

.zoomed {
  text-align: center;
  font-weight: bold;
}

.zoomed img {
  display: block;
  margin: auto;
  margin-bottom: 1rem;
}

@media screen and (max-width: 600px) {
  .actions button {
    font-size: 1.8rem;
  }

  .actions button img {
    height: 1.5rem;
    margin-bottom: 0;
  }

  .consumables-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
}
</style>
