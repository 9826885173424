<template>
  <main class="content">
    <div class="inner content-grid">
      <div class="left">
        <page-heading heading="Report Shortage – Log a claim" icon="report-shortage-o.png" :para="success_message
          ? 'Thank you, your claim has now been submitted.'
          : 'Please fill out the fields below to report your issue.'
          "></page-heading>
        <template v-if="!success_message">
          <info-panel content="Fill out as much information as you can to help process your claim quickly."></info-panel>
          <div class="form">
            <div class="form-row">
              <div class="form-label" id="lblDateOfLoss">Date of loss:</div>
              <div class="form-input-holder">
                <flat-pickr v-model="date_of_loss" ref="dateWrapperElement" :config="date_config"
                  class="txt full date"></flat-pickr>
              </div>
            </div>
            <div class="form-row">
              <div id="lblExpectedAmount" class="form-label">Expected amount (£):</div>
              <div class="form-input-holder">
                <input aria-labelledby="lblExpectedAmount" v-model.number="expected_amount" class="form-input"
                  type="number" placeholder="0.00" />
              </div>
            </div>
            <div class="form-row">
              <div id="lblActualAmount" class="form-label">Actual amount (£):</div>
              <div class="form-input-holder">
                <input aria-labelledby="lblActualAmount" v-model.number="actual_amount" class="form-input" type="number"
                  placeholder="0.00" />
              </div>
            </div>
            <div class="form-row">
              <div id="lblBagNumber" class="form-label">Bag number:</div>
              <div class="form-input-holder">
                <input aria-labelledby="lblBagNumber" v-model="bag_number" type="text" class="form-input"
                  placeholder="Bag Number (if known / optional)" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">Do you have a proof of delivery receipt for this service?</div>
              <div class="form-input-holder">
                <div class="radios">
                  <div class="radio">
                    <input type="radio" v-model="photo" name="radPhoto" :value="0" id="radPhotoNo" />
                    <label for="radPhotoNo">No</label>
                  </div>
                  <div class="radio">
                    <input type="radio" v-model="photo" name="radPhoto" :value="1" id="radPhotoYes" />
                    <label for="radPhotoYes">Yes</label>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="photo">
              <div class="form-row">
                <div class="form-label">Please upload your photo:</div>
                <div class="form-input-holder">
                  <div class="note">
                    NOTE: Please ensure your files are in JPEG (.jpg) format.<br />You can attach up to 3 files with a
                    limit of 5mb per file.
                  </div>
                  <div>
                    <input v-if="refresh" type="file" accept="image/jpeg" @change="upload($event, 'file_1')"
                      class="form-input form-file" id="file-input-1" />
                    <div class="uploaded" v-if="total_files >= 2">
                      {{ file_1_name }}
                      <a href @click.prevent="deleteFile(1)" class="delete">x</a>
                    </div>
                  </div>
                  <div>
                    <input v-if="refresh" type="file" accept="image/jpeg" @change="upload($event, 'file_2')"
                      class="form-input form-file" id="file-input-2" />
                    <div class="uploaded" v-if="total_files >= 3">
                      {{ file_2_name }}
                      <a href @click.prevent="deleteFile(2)" class="delete">x</a>
                    </div>
                  </div>
                  <div>
                    <input v-if="refresh" type="file" accept="image/jpeg" @change="upload($event, 'file_3')"
                      class="form-input form-file" id="file-input-3" />
                    <div class="uploaded" v-if="total_files >= 4">
                      {{ file_3_name }}
                      <a href @click.prevent="deleteFile(3)" class="delete">x</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div></div>
                <div class="form-input-holder">
                  <button v-if="total_files < 4" class="upload" @click="triggerUploader()">
                    {{ total_files > 1 ? "Add another" : "Click to attach" }}
                    <img src="/images/icons/upload.png" alt="Upload" />
                  </button>
                </div>
              </div>
            </template>
            <div class="form-row">
              <div id="lblAdditionalNotes" class="form-label">Additional notes:</div>
              <div class="form-input-holder">
                <textarea aria-labelledby="lblAdditionalNotes" v-model="notes" class="form-input form-textarea"
                  placeholder="If there are any other details you would like to add, please do so here..."></textarea>
              </div>
            </div>
          </div>

          <error-panel v-if="error_message" :content="error_message"></error-panel>

          <div class="actions">
            <button @click="clearAll" class="negative">
              Clear All Fields <img src="/images/icons/button-icon-cancel.png" alt="Clear all fields" />
            </button>
            <button :disabled="submit_loading" @click="submit">
              <template v-if="submit_loading">
                <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
              </template>
              <template v-else> Submit <img src="/images/icons/button-icon-submit.png" alt="submit" /> </template>
            </button>
          </div>
        </template>
        <template v-else>
          <success-panel :content="success_message"></success-panel>
        </template>
      </div>
      <right-column></right-column>
    </div>
  </main>
</template>

<script>
import api from "@/services/api"

var maxDate = new Date()

export default {
  data() {
    return {
      date_of_loss: null,
      expected_amount: "",
      actual_amount: "",
      bag_number: "",
      photo: 0,
      file_1_data: "",
      file_2_data: "",
      file_3_data: "",
      file_1_name: "",
      file_2_name: "",
      file_3_name: "",
      notes: "",

      refresh: true,
      submit_loading: false,

      date_config: {
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ["S", "M", "T", "W", "T", "F", "S"]
          }
        },
        maxDate,
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        disableMobile: "true"
      },

      error_message: "",
      success_message: "",

      total_files: 1
    }
  },
  mounted() {
    const dateWrapperElement = this.$refs.dateWrapperElement.getElem().nextElementSibling
    dateWrapperElement.setAttribute("aria-label", "Date of loss")
  },
  methods: {
    triggerUploader() {
      document.getElementById("file-input-" + this.total_files).click()
    },
    deleteFile(i) {
      this.refresh = false
      this.$nextTick().then(() => (this.refresh = true))

      this.total_files--

      if (i == 1) {
        this["file_1_data"] = this["file_2_data"]
        this["file_1_name"] = this["file_2_name"]
      }
      if (i <= 2) {
        this["file_2_data"] = this["file_3_data"]
        this["file_2_name"] = this["file_3_name"]
      }
      this["file_3_data"] = ""
      this["file_3_name"] = ""
    },
    clearAll() {
      this.date_of_loss = null
      this.expected_amount = ""
      this.actual_amount = ""
      this.bag_number = ""
      this.photo = 0
      this.total_files = 1
      this.file_1_data = ""
      this.file_2_data = ""
      this.file_3_data = ""
      this.file_1_name = ""
      this.file_2_name = ""
      this.file_3_name = ""
      this.notes = ""
    },
    upload: function (e, prop) {
      this.success_message = ""
      this.error_message = ""

      var files = e.target.files || e.dataTransfer.files

      if (!files.length) return
      if (files[0].size > 5242880) return (this.error_message = "Please ensure all files are under 5mb")

      var reader = new FileReader()
      reader.addEventListener(
        "load",
        () => {
          if (!reader.result.startsWith("data:image/jpeg"))
            return (this.error_message = "Please select a valid jpg file")

          this[prop + "_data"] = reader.result
          this[prop + "_name"] = files[0].name
          this.total_files++
        },
        false
      )

      reader.readAsDataURL(files[0])
    },
    submit() {
      this.submit_loading = true
      this.error_message = ""
      this.success_message = ""

      if (!this.expected_amount) this.expected_amount = 0
      if (!this.actual_amount) this.actual_amount = 0

      if (!this.date_of_loss) {
        this.error_message = "Please fill in the date of loss"
        this.submit_loading = false
        return
      }

      if (this.expected_amount == this.actual_amount) {
        this.error_message = "The expected amount must be different to the actual amount"
        this.submit_loading = false
        return
      }

      if (
        this.photo &&
        !(
          this.file_1_data ||
          this.file_2_data ||
          this.file_3_data ||
          this.file_1_name ||
          this.file_2_name ||
          this.file_3_name
        )
      ) {
        this.error_message = "Don't forget to upload your photo"
        this.submit_loading = false
        return
      }

      api
        .request("post", "shortage", {
          date_of_loss: this.date_of_loss,
          expected_amount: this.expected_amount,
          actual_amount: this.actual_amount,
          bag_number: this.bag_number,
          photo: this.photo,
          file_1_data: this.file_1_data,
          file_2_data: this.file_2_data,
          file_3_data: this.file_3_data,
          file_1_name: this.file_1_name,
          file_2_name: this.file_2_name,
          file_3_name: this.file_3_name,
          notes: this.notes
        })
        .then(() => {
          this.clearAll()
          this.success_message =
            "A confirmation email will be sent to you with details about the shortage / discrepancy raised."
        })
        .catch(err => {
          this.error_message = "There was an error, please try again"
        })
        .finally(() => {
          this.submit_loading = false
        })
    }
  }
}
</script>

<style scoped>
.form-row {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 5rem;
  margin-bottom: 2rem;
}

.form-input {
  width: 100%;
}

.form-label {
  padding: 1rem 0;
}

.form-input::placeholder {
  color: #ccc;
}

.form-textarea {
  height: 15rem;
}

.radio {
  display: inline-block;
  margin: 1.5rem 0;
  margin-right: 3rem;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 2.8rem;
  cursor: pointer;
  line-height: 2rem;
  display: inline-block;
  font-size: 2rem;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  background: #ca0028;
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type="radio"]:focus-visible+label:before {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

.note {
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  opacity: 0.7;
}

.btn-another,
.upload {
  background: #ca0028;
  color: white;
  border-radius: 3rem;
  border: 0;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  cursor: pointer;
  margin-bottom: 2rem;
}

.upload img {
  height: 1.8rem;
  position: relative;
  bottom: -0.2rem;
  margin-left: 0.5rem;
}

.form-file {
  display: none;
}

.form-right {
  text-align: right;
}

.delete {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background: #5f6062;
  color: white;
  text-align: center;
  line-height: 1.5rem;
  position: relative;
  top: -0.25rem;
  margin-left: 1rem;
}

.delete:hover {
  background: #ca0028;
}

.uploaded {
  margin: 0.5rem 0;
}

@media screen and (max-width: 600px) {
  .actions button {
    font-size: 1.8rem;
  }

  .actions button img {
    height: 1.5rem;
    margin-bottom: 0;
  }

  .form-row {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
</style>
