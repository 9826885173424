<template>
  <main class="content">
    <div class="inner content-grid">
      <div class="left">
        <page-heading heading="Download Location Barcode" icon="barcode-o.png" :para="success_message
          ? 'Your location barcode has now been downloaded.'
          : 'Your location barcode is now generated and ready to download to your device.'
          "></page-heading>
        <template v-if="!success_message">
          <success-panel :content="'To download, simply click on the link below'"></success-panel>
          <a href @click.prevent="downloadPDF"><img src="/images/location-barcode.jpg" alt="Download PDF" /></a>
        </template>
        <template v-else>
          <success-panel
            :content="'If you need another barcode for any reason, you can either re-use the PDF you have, or alternatively, come back to this area to generate another.'"></success-panel>
          <p class="back"><router-link to="/home">&lt; Back to Dashboard...</router-link></p>
        </template>
      </div>
      <right-column></right-column>
    </div>
  </main>
</template>

<script>
import { jsPDF } from "jspdf"
import JsBarcode from "jsbarcode"
import api from "@/services/api"

export default {
  data() {
    return {
      success_message: ""
    }
  },
  computed: {
    LocationName() {
      if (this.$store.state.user_type == "customer") {
        return this.$store.state.customer.company
      }
      if (this.$store.state.user_type == "hq-as-customer") {
        return this.$store.state.customer.company
      }
      return ""
    },
    AccountNumberForBarcode() {
      var accnum = this.$store.state.customer.account_number
      accnum = accnum.substring(2)
      return accnum
    },
    barcodeImageBase64() {
      var canvas = document.createElement("canvas")
      JsBarcode(canvas, this.AccountNumberForBarcode, { format: "CODE39" })
      return canvas.toDataURL("image/png")
    }
  },
  methods: {
    downloadPDF() {
      var img = new Image()
      img.src = this.barcodeImageBase64

      const doc = new jsPDF("p", "px", "a4")
      const pageWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight()

      doc.setTextColor(255, 0, 0);
      doc.setFontSize(16);
      doc.setFont("Helvetica", "")
      doc.text("Please do not laminate this barcode", doc.internal.pageSize.getWidth() / 2, 83, null, null, "center");


      var canvasWidth = 150
      var canvasHeight = 53

      var marginX = (pageWidth - canvasWidth) / 2
      var marginY = 380
      doc.addImage(img, "JPEG", marginX, marginY, canvasWidth, canvasHeight)

      canvasWidth = 200
      canvasHeight = 123

      marginX = (pageWidth - canvasWidth) / 2
      marginY = 100
      img.src = "/images/logo.jpg"

      doc.addImage(img, "JPEG", marginX, marginY, canvasWidth, canvasHeight)

      doc.line(50, 50, 50, 60)
      doc.line(50, 50, 60, 50)

      doc.line(pageWidth - 50, 50, pageWidth - 50, 60)
      doc.line(pageWidth - 50, 50, pageWidth - 60, 50)

      doc.line(pageWidth - 50, pageHeight - 50, pageWidth - 50, pageHeight - 60)
      doc.line(pageWidth - 50, pageHeight - 50, pageWidth - 60, pageHeight - 50)

      doc.line(50, pageHeight - 50, 50, pageHeight - 60)
      doc.line(50, pageHeight - 50, 60, pageHeight - 50)

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(30)
      doc.setFont("Helvetica", "Bold")
      doc.text("COLLECTION POINT", doc.internal.pageSize.getWidth() / 2, 280, null, null, "center")
      doc.setFont("Helvetica", "")
      doc.setFontSize(17)
      doc.text("CUSTOMER DETAILS", doc.internal.pageSize.getWidth() / 2, 305, null, null, "center")
      doc.setFontSize(20)
      doc.text(this.LocationName, doc.internal.pageSize.getWidth() / 2, 355, null, null, "center")
      doc.setFontSize(11)
      doc.text(
        "THIS CARD MUST BE AVAILABLE FOR OUR CREW REPRESENTATIVE UPON",
        doc.internal.pageSize.getWidth() / 2,
        470,
        null,
        null,
        "center"
      )
      doc.text("DELIVERY OR COLLECTION OF BAGS", doc.internal.pageSize.getWidth() / 2, 485, null, null, "center")

      doc.save("Loomis Location Barcode.pdf")

      this.success_message = "success"

      api.request("post", "log-activity-customer?type=barcode")
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 600px) {}
</style>
