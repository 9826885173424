<template>
  <main class="content">
    <div class="inner content-grid">
      <div class="left">
        <page-heading heading="Update Password" icon="update-password-o.png"
          para="Please fill in the fields below to update your password."></page-heading>
        <info-panel
          content="Your new password must be a minimum of 10 characters, a letter, a number, and a special character."></info-panel>
        <div class="detail editable">
          <label class="detail-label" id="lblCurrentPassword">Current Password:</label>
          <div class="detail-input">
            <input aria-labelledby="lblCurrentPassword" v-model="current_password" class="detail-input-text"
              :type="revealcurrent ? 'text' : 'password'" />
            <a href class="reveal" @click.prevent="revealcurrent = !revealcurrent"><img v-if="revealcurrent == false"
                src="/images/icons/field-icon-reveal.png" alt="Show password" />
              <img v-else class="reveal" src="/images/icons/field-icon-hide.png" alt="Hide password" /></a>
          </div>
        </div>
        <div class="detail editable">
          <label class="detail-label" id="lblNewPassword">New Password:</label>
          <div class="detail-input">
            <input aria-labelledby="lblNewPassword" v-model="new_password" class="detail-input-text"
              :type="reveal1 ? 'text' : 'password'" />
            <a href class="reveal" @click.prevent="reveal1 = !reveal1"><img v-if="reveal1 == false"
                src="/images/icons/field-icon-reveal.png" alt="Show password" />
              <img v-else class="reveal" src="/images/icons/field-icon-hide.png" alt="Hide password" /></a>
          </div>
        </div>
        <div class="detail editable">
          <label class="detail-label" id="lblConfirmNewPassword">Confirm New Password:</label>
          <div class="detail-input">
            <input aria-labelledby="lblConfirmNewPassword" v-model="confirm_password" class="detail-input-text"
              :type="reveal2 ? 'text' : 'password'" />
            <a href class="reveal" @click.prevent="reveal2 = !reveal2"><img v-if="reveal2 == false"
                src="/images/icons/field-icon-reveal.png" alt="Show password" />
              <img v-else class="reveal" src="/images/icons/field-icon-hide.png" alt="Hide password" /></a>
          </div>
        </div>

        <error-panel v-if="error_message" :content="error_message"></error-panel>
        <success-panel v-if="success_message" :content="success_message"></success-panel>

        <div class="actions">
          <button class="negative" @click="cancel">
            <template v-if="success_message"> Go Back </template>
            <template v-else>Cancel <img src="/images/icons/button-icon-cancel.png" alt="cancel" /></template>
          </button>
          <button @click="changePassword">
            Submit <img src="/images/icons/button-icon-submit.png" alt="submit" />
          </button>
        </div>
      </div>
      <right-column></right-column>
    </div>
  </main>
</template>

<script>
import cognitoUser from "@/services/cognito-user"
import api from "@/services/api"

export default {
  data() {
    return {
      revealcurrent: false,
      reveal1: false,
      reveal2: false,

      success_message: "",
      error_message: "",
      current_password: "",
      new_password: "",
      confirm_password: ""
    }
  },
  methods: {
    cancel() {
      if (this.$store.state.user_type == "customer") this.$router.push({ name: "Home" })
      if (this.$store.state.user_type == "hq-user") this.$router.push({ name: "HQ Home" })
    },
    changePassword() {
      this.error_message = ""
      this.success_message = ""

      if (!this.current_password || !this.new_password || !this.confirm_password) {
        this.error_message = "Please fill in all fields"
        return
      }

      if (this.new_password !== this.confirm_password) {
        this.error_message = "The new passwords do not match"
        return
      }

      cognitoUser
        .changePassword(this.current_password, this.new_password)
        .then(() => {
          if (this.$store.state.user_type == "customer")
            api.request("post", "user-customer?type=customer-send-updated-password-email")
          if (this.$store.state.user_type == "hq-user")
            api.request("post", "user-hq?type=customer-send-updated-password-email")

          this.current_password = ""
          this.new_password = ""
          this.confirm_password = ""

          this.success_message = "Your password has been updated"
        })
        .catch(err => {
          switch (err.code) {
            case "InvalidPasswordException":
              this.error_message = "Invalid password: Please choose a password with at least 10 characters, a letter, a number, and a special character"
              break
            case "NotAuthorizedException":
              this.error_message = "Current password incorrect"
              break
            default:
              this.error_message = "There was an error, please try again"
          }
        })
    }
  }
}
</script>
