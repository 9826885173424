<template>
  <main class="content">
    <div class="inner content-grid">
      <div class="left">
        <page-heading heading="View / Edit Account Info" icon="view-edit-account-info-o.png"
          para="See details held on your account below."></page-heading>
        <info-panel
          content="To change or update your details, click the ‘Edit’ icon on the right hand side."></info-panel>
        <div class="detail detail-static" v-if="$store.state.user_type == 'customer'">
          <label class="detail-label">Account Number:</label>
          <div class="detail-content">{{ customer.account_number }}</div>
        </div>
        <div class="detail detail-static" v-if="$store.state.user_type == 'hq-user'">
          <label class="detail-label">Prefix:</label>
          <div class="detail-content">{{ customer.prefix }}</div>
        </div>
        <div class="detail" :class="{ editing: item.editing, detail_error: item.error }" :key="item.label"
          v-for="item in values">
          <label class="detail-label" :for="item.editing ? item.label : ''">{{ item.label }}:</label>
          <div class="detail-input" v-if="item.editing">
            <input :id="item.label" class="detail-input-text" :type="item.type" v-model.trim="item.content" />
          </div>
          <div class="detail-content" v-if="!item.editing">{{ item.content }}</div>
          <a class="detail-edit" @click.prevent="item.editing = !item.editing" href><span>Edit <img
                src="/images/icons/edit-icon-o.png" alt="edit" /></span></a>
          <span class="detail_error_content" v-if="item.error">{{ item.error }}</span>
        </div>

        <error-panel v-if="error_message" :content="error_message"></error-panel>
        <success-panel v-if="success_message" :content="success_message"></success-panel>

        <div class="actions">
          <button class="negative" @click="cancel">
            <template v-if="success_message"> Go Back </template>
            <template v-else>Cancel <img src="/images/icons/button-icon-cancel.png" alt="cancel" /></template>
          </button>
          <button @click="submit" v-if="hasChanges">
            Submit <img src="/images/icons/button-icon-submit.png" alt="submit" />
          </button>
        </div>
      </div>
      <right-column></right-column>
    </div>
  </main>
</template>

<script>
import cognito from "@/services/cognito-user"
import phoneNumber from "awesome-phonenumber"
import * as emailValidator from "email-validator"
import api from "@/services/api"

export default {
  data() {
    return {
      values: [],

      error_message: "",
      success_message: ""
    }
  },
  created() {
    this.values = JSON.parse(JSON.stringify(this.$store.state.myDetails))
  },
  computed: {
    customer() {
      return this.$store.state.customer || this.$store.state.hq_user
    },
    authType() {
      return this.$store.state.authType
    },
    isValid() {
      return !this.values.filter(val => val.error).length
    },
    hasChanges() {
      return !!this.values.filter(val => val.content != val._content).length
    }
  },
  methods: {
    cancel() {
      var editing = false
      this.values.forEach(val => {
        if (val.editing) {
          editing = true
          val.editing = false
          val.error = ""
          val.content = val._content
        }
      })

      if (!editing) {
        if (this.$store.state.user_type == "customer") this.$router.push({ name: "Home" })
        if (this.$store.state.user_type == "hq-user") this.$router.push({ name: "HQ Home" })
      }
    },
    submit() {
      this.error_message = ""
      this.success_message = ""

      var user = {}
      var old_user = {}

      this.values.forEach(val => {
        if (!val.content) {
          this.$set(val, "error", "Please enter a value")
          return
        }

        if (val.type == "email") {
          if (!emailValidator.validate(val.content)) {
            this.$set(val, "error", "Please enter a valid email address")
            return
          }

          if (this.authType == "Email") {
            if (!this.customer.domain.split(",").includes(val.content.split("@")[1])) {
              this.$set(
                val,
                "error",
                "Sorry, we can't accept this email address. Please ensure the address specified uses your company email domain."
              )
              return
            }
          }
        }

        if (val.type == "tel") {
          var pn = new phoneNumber(val.content, "GB")
          if (!pn.isValid()) {
            this.$set(val, "error", "Please enter a valid phone number")
            return
          } else if (val.property == "phone_number" && !pn.isMobile() && this.authType !== "Email") {
            this.$set(
              val,
              "error",
              "When you log into the portal we send your verification codes by SMS, so please enter a business mobile number rather than a landline."
            )
            return
          } else val.content = pn.getNumber()
        }

        this.$set(val, "error", "")
        user[val.property] = val.content
        old_user[val.property] = val._content
      })

      if (this.isValid) {
        var url = "user-customer"

        if (this.$store.state.user_type == "hq-user") url = "user-hq"

        api
          .request("put", url, { new_user: user, old_user })
          .then(() => {
            this.values.forEach(val => {
              val.editing = false
              val.error = ""
              val._content = val.content
            })

            this.success_message = "Your details have been updated"

            cognito.getSessionDetails().then(res => {
              res.name = user.name
              res.email = user.email
              res.phone_number = user.phone_number
              res["custom:phone"] = user["custom:phone"]

              this.$store.commit("setSessionDetails", res)
            })

            if (this.$store.state.user_type == "customer")
              api.request("get", "customer").then(customer => {
                this.$store.commit("setCustomerDetails", customer)
              })

            if (this.$store.state.user_type == "hq-user")
              api.request("get", "user-hq").then(user => {
                this.$store.commit("setHQUser", user)
                this.$store.commit("setHQCashProperties", user)
              })
          })
          .catch(err => {
            if (err.response && err.response.status == 400 && err.response.data == "Domain error")
              this.error_message =
                "Sorry, we can't accept this email address. Please ensure the address specified uses your company email domain."
            else this.error_message = "There has been an error, please try again"
          })
      }
    }
  }
}
</script>
